<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <HomePage />
    </template>
    <template #fallback>
      <Loding />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
// @ is an alias to /src
import { defineAsyncComponent, Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
// import HomePage from "@/components/Pages/HomePage.vue";
const HomePage = defineAsyncComponent(() =>
  import("@/components/Pages/HomePage.vue")
);
export default {
  name: "Home",
  components: {
    Suspense,
    Loding,
    AppHeader,
    AppFooter,
    HomePage,
  },
};
</script>
